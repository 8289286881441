<template>
  <div>
    <div class="container">
      <img src="@/assets/img/mock/BehaviorInterview/banner1.png" alt="" />
      <img src="@/assets/img/mock/BehaviorInterview/banner2.png" alt="" />
      <img src="@/assets/img/mock/BehaviorInterview/banner3.png" alt="" />
      <img src="@/assets/img/mock/BehaviorInterview/banner4.png" alt="" />
      <img src="@/assets/img/mock/BehaviorInterview/banner5.png" alt="" />
      <img src="@/assets/img/mock/BehaviorInterview/banner6.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "BehaviorInterview",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.container {
  img {
    width: 100%;
    vertical-align: top;
  }
}
</style>